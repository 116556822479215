<script lang="ts" setup>
import Logo from '@/components/Logo.vue'
import MainNav from '@/components/MainNav.vue'
import MiniNav from '@/components/MiniNav.vue'
import SubNavigation from '@/components/navigation/SubNavigation.vue'
import BottomNavigation from '@/components/navigation/BottomNavigation.vue'

const route = useRoute()
const layoutMeta = computed(
  () =>
    ({
      subNavType: null,
      contentWidth: 'contained',
      ...route.meta.main,
    }) satisfies CustomPageMeta['main']
)

const { url: srBrandingLogoUrl } = storeToRefs(useSrBrandingStore())

const showDrawer = ref(false)

const { xs, smAndDown, smAndUp, mdAndUp } = useDisplay()

const isBottomNav = computed(() => smAndDown.value)

const appBarHeight = computed(() => (smAndUp.value ? 90 : 70))

const subNavType = computed(() => layoutMeta.value.subNavType)

const contentWidth = computed(() => {
  if (layoutMeta.value.contentWidth === 'full') {
    return '100%'
  }
  return '830px'
})

const { navigationItems } = useSubNav(subNavType)
</script>

<template>
  <v-main>
    <v-app-bar absolute flat order="1" :height="appBarHeight" class="app-bar">
      <div v-if="smAndDown" class="menu-toggle">
        <v-app-bar-nav-icon :ripple="false" @click="showDrawer = !showDrawer">
          <v-icon size="x-large">custom:menu</v-icon>
        </v-app-bar-nav-icon>
      </div>
      <slot name="appbarContent">
        <logo data-cy="indexLogo" class="sh__logo" :image="srBrandingLogoUrl" />
      </slot>
    </v-app-bar>
    <main-nav order="0" :active="showDrawer" @changed="showDrawer = $event" />
    <mini-nav v-if="mdAndUp" order="0" @clicked="showDrawer = !showDrawer" />
    <v-container fluid class="fill-height">
      <v-row class="fill-height" :no-gutters="xs">
        <v-col class="flex-grow-0">
          <div class="sub-nav">
            <slot name="subNav">
              <sub-navigation v-if="!isBottomNav && navigationItems.length" :items="navigationItems" />
            </slot>
          </div>
        </v-col>
        <v-col>
          <div class="content">
            <slot />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <slot name="widgets" />
    <slot name="bottomNav">
      <bottom-navigation v-if="isBottomNav && navigationItems.length" order="-1" :items="navigationItems" />
    </slot>
  </v-main>
</template>

<style lang="scss" scoped>
.app-bar {
  background-color: $c-white;
  padding-left: 4 * $bw;
  padding-right: 4 * $bw;

  @include miw(lg) {
    background-color: transparent;
  }

  .menu-toggle {
    margin-right: 4 * $bw;
    &:hover {
      color: $c-primary;
    }
  }
}

.sub-nav {
  position: sticky;
  top: 22px;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: v-bind(contentWidth);
  margin: 0 auto;
}
</style>
