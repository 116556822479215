<script lang="ts" setup>
import Avatar from '@/components/Avatar.vue'
import PopOverNav from '@/components/navigation/PopOverNav.vue'

const businessStore = useBusinessStore()
const { isBusinessAdmin, isBusinessMember } = storeToRefs(useUserStore())
const systemStore = useSystemStore()

const { t } = useI18n()

const route = useRoute()

const { mdAndUp } = useDisplay()

const { activePricePlanId } = useUserPlanInfo()

const isUploadAllowed = computed(() => isBusinessAdmin.value || isBusinessMember.value)

const { navigationItems } = useSubNav('profile')

const platform = computed(() => systemStore.platform || 'CH')
const supportContact = computed(() => businessStore.settings.supportContact)

const emailContactLink = computed(() => {
  return supportContact.value?.email
    ? `mailto:${supportContact.value.email}`
    : platform.value === 'EU'
      ? t('global.navigation.contact.link_form_de')
      : t('global.navigation.contact.link')
})

const goToAdmin = computed(() => {
  if (['TRIAL', 'TEAM', 'PRO', 'SCALE'].some(plan => activePricePlanId.value?.startsWith(plan))) {
    return 'business-dashboard'
  } else if (activePricePlanId.value?.startsWith('INDIVIDUAL')) {
    return 'business-billing-plan'
  } else {
    return 'business-members'
  }
})

const clickHelpIcon = () => {
  // Do not open link on smaller screens to allow touchscreens to hover
  if (mdAndUp.value) {
    window.open(t('global.navigation.helpcenter.link'))
  }
}
</script>

<template>
  <!--
    TODO: This and MainNav.vue are very similar and should be
    refactored for less duplication.
    Maybe even consider MainNavBusiness.vue and MiniNavBusiness.vue too.
  -->

  <!--
    Note: the mini nav gets a simulated clipped applied by styling through
    the has-alert class on the application tag. We just add the margin-top
    and the height with important to overwrite the inline styling set by
    Vuetify.

    Adding the clipped attribute thus has no effect on this element.
  -->
  <v-navigation-drawer floating class="mini-nav" rail rail-width="90" :mobile-breakpoint="600">
    <div class="d-flex flex-column | mini-nav__flex">
      <v-app-bar-nav-icon
        :ripple="false"
        class="mini-nav__trigger"
        data-cy="mini_navigation_menu"
        @click.stop="$emit('clicked')"
      >
        <v-icon size="x-large">custom:menu</v-icon>
      </v-app-bar-nav-icon>
      <v-list class="py-0" nav>
        <v-tooltip content-class="text-center bg-info" location="right" max-width="207" :disabled="isUploadAllowed">
          <template #activator="{ props: activatorProps }">
            <div v-bind="activatorProps">
              <v-list-item
                :disabled="!isUploadAllowed"
                :ripple="false"
                class="mini-nav__new"
                data-cy="create_doc_button"
                exact
                :to="{ name: 'upload' }"
              >
                <v-list-item-action class="align-center flex-column my-3">
                  <v-icon size="40" :color="isUploadAllowed ? 'primary' : '#D8D8D8'">custom:new_btn_desktop</v-icon>
                  <span class="mini-nav__label">
                    {{ t('global.navigation.new') }}
                  </span>
                </v-list-item-action>
              </v-list-item>
            </div>
          </template>
          <span>{{ t('global.navigation.tooltip.upload_blocked') }}</span>
        </v-tooltip>
        <v-list-item
          :ripple="false"
          :active="route.name === 'index' && route.query.tab != 'drafts'"
          color="primary"
          data-cy="mini_navigation_inbox"
          :to="{ name: 'index', query: { tab: 'inbox', filter: 'toSign' } }"
        >
          <v-list-item-action class="align-center flex-column my-3">
            <div class="mini-nav__border" />
            <v-icon>custom:folder_user</v-icon>
            <span class="mini-nav__label">
              {{ t('global.navigation.inbox') }}
            </span>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          :ripple="false"
          :active="route.query.tab === 'drafts'"
          color="primary"
          data-cy="mini_navigation_drafts"
          :to="{ name: 'index', query: { tab: 'drafts' } }"
        >
          <v-list-item-action class="align-center flex-column my-3">
            <div class="mini-nav__border" />
            <v-icon>custom:draft</v-icon>
            <span class="mini-nav__label">
              {{ t('global.navigation.drafts') }}
            </span>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-list class="mt-auto py-0" nav>
        <v-menu
          location="end center"
          content-class="mini-nav__help-overview"
          open-delay="150"
          open-on-hover
          :close-on-content-click="true"
        >
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              :ripple="false"
              color="primary"
              exact
              data-cy="help_icon"
              @click="clickHelpIcon"
            >
              <v-list-item-action class="align-center flex-column my-3">
                <v-icon>custom:c_question</v-icon>
                <span class="mini-nav__label">{{ t('global.navigation.help_center') }}</span>
              </v-list-item-action>
            </v-list-item>
          </template>
          <v-list nav class="pa-1">
            <v-list-item :href="t('global.navigation.helpcenter.link')" target="_blank" rel="noopener">
              <template #prepend>
                <v-icon>custom:help_center</v-icon>
              </template>
              <v-list-item-title>
                {{ t('global.navigation.helpcenter.label') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item :href="t('global.navigation.tutorials.link')" target="_blank" rel="noopener">
              <template #prepend>
                <v-icon>custom:video_tutorials</v-icon>
              </template>
              <v-list-item-title>
                {{ t('global.navigation.tutorials.label') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item :href="emailContactLink" target="_blank" rel="noopener" data-cy="support_form">
              <template #prepend>
                <v-icon>custom:contact_email</v-icon>
              </template>
              <v-list-item-title>
                {{
                  supportContact.email
                    ? t('global.navigation.internal_support_contact.mail.label')
                    : t('global.navigation.contact.label')
                }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="supportContact?.phone"
              :href="`tel:${supportContact.phone}`"
              target="_blank"
              rel="noopener"
              data-cy="support_phone_number"
            >
              <template #prepend>
                <v-icon>custom:support_hotline</v-icon>
              </template>
              <v-list-item-title>
                {{ t('global.navigation.call.label') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ supportContact.phone ?? t('global.skribble_support_phone_number') }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-list-item v-if="isBusinessAdmin" :ripple="false" color="primary" exact :to="{ name: goToAdmin }">
          <v-list-item-action class="align-center flex-column my-3">
            <v-icon>custom:business_account</v-icon>
            <span class="mini-nav__label">
              {{ t('global.navigation.admin_area') }}
            </span>
          </v-list-item-action>
        </v-list-item>

        <v-list class="mt-auto py-0" nav>
          <v-menu location="end center" open-delay="150" open-on-hover :close-on-content-click="true">
            <template #activator="{ props }">
              <v-list-item
                :ripple="false"
                color="primary"
                class="mini-nav__avatar"
                :to="mdAndUp ? { name: 'profile' } : route"
                data-cy="profileButton"
                v-bind="props"
              >
                <v-list-item-action class="align-center flex-column my-3">
                  <div class="mini-nav__border" />
                  <avatar />
                  <span class="mini-nav__label">{{ t('global.profile.profile') }}</span>
                </v-list-item-action>
              </v-list-item>
            </template>
            <pop-over-nav :items="navigationItems" />
          </v-menu>
        </v-list>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<style lang="scss">
.mini-nav {
  padding-bottom: 8px;
  font-size: 0.75rem;
  font-weight: bold;

  .v-list--nav {
    padding: 0px;
  }

  .v-list-item {
    padding-left: 0;
    padding-right: 0;
  }

  .avatar {
    font-size: 0.875rem;
  }

  &:not(.business) {
    .v-list-item {
      &.v-theme--light {
        @include mini-nav-item-hover;
        margin: 4px 10px 4px 10px;
        &:not(.v-list-item--active) {
          &:hover {
            background: rgba(151, 151, 151, 0.1);
            .v-list-item-action,
            .v-icon {
              color: $c-grey-dark;
            }
          }
        }
        &.mini-nav__new {
          &:before {
            top: 9px;
            width: 46px;
            height: 46px;
          }
          &:not(.v-list-item--disabled) span {
            color: $c-primary;
          }
          .v-list-item--disabled span {
            color: #d8d8d8;
          }
          .v-list-item--active {
            color: $c-primary;
          }
        }
      }
      &__action:first-child {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .mini-nav__flex {
      height: 100%;
      background: $c-grey-light-3;
    }
  }

  &__trigger {
    margin-bottom: 20px;
    width: 100% !important;
    height: 90px !important;
    border-radius: 0;
    span {
      i {
        height: 26px !important;
        font-size: 34px !important;
        width: 26px !important;
      }
    }
    &:hover {
      background: rgba(151, 151, 151, 0.1);
    }
  }

  &__label {
    margin-top: 9px;
    padding-left: 4px;
    padding-right: 4px;
    text-align: center;
    white-space: normal;
    line-height: 1.2;
  }

  &__signing-status {
    position: absolute;
    right: 18px;
    top: -2px;
    width: 16px;
    height: 16px;
  }

  &__avatar {
    &:before {
      display: none;
    }
  }

  &__help-overview {
    .v-list-item {
      &__content {
        width: min-content;
        font-size: 0.75rem;
        color: $c-grey-dark;

        .v-list-item-title {
          font-weight: 700;
          font-size: 0.875rem;
        }
      }
      &__sub-title,
      .v-list-item__action > span > svg {
        font-size: 0.75rem;
        color: $c-grey-dark;
      }
    }
  }
}
</style>
