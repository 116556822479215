<script lang="ts" setup>
defineProps<{
  items: (NavigationItem | NavigationDivider)[]
}>()

const isNavItem = (item: NavigationItem | NavigationDivider): item is NavigationItem => !item.divider
</script>

<template>
  <v-list nav class="popover-nav">
    <template v-for="(item, i) in items" :key="`${item}-${i}`">
      <v-list-item v-if="isNavItem(item)" :id="item.identifier" :to="item.route">
        <template #prepend>
          <v-icon>{{ item.icon }}</v-icon>
        </template>
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
      <v-divider v-else />
    </template>
  </v-list>
</template>

<style lang="sass" scoped>
.popover-nav
  padding: 1 * $bw
  .v-list-item--link::before
    background-color: unset
  .v-list-item
    align-content: center
    cursor: pointer
    &__content
      width: min-content
      font-size: 0.75rem
      color: $c-grey-dark

      &:hover
        color: $c-primary !important

      .v-list-item-title
        font-weight: 700
        font-size: .875rem
</style>
